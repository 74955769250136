import React from "react"
import { Helmet } from "react-helmet"
import PageWithBackgroundImage from "../components/pageWithBackgroundImage"
import "../styles/404.css"
export default function ActivateClientAccount({ location }) {
  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div id="notFoundPage">
        <div className="notFoundPageFirstColumn nfpArrowDesktop">
          <svg
            id="notFound-leftArrow"
            width="141"
            height="546"
            viewBox="0 0 141 663"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="88"
              y1="660.5"
              x2="140.038"
              y2="660.5"
              stroke="#002F49"
              stroke-width="3"
            />
            <path
              d="M10.9288 0.822207C10.2784 0.309251 9.33518 0.420718 8.82219 1.07118L0.462581 11.6715C-0.0504107 12.322 0.0610589 13.2652 0.711552 13.7782C1.36204 14.2912 2.30522 14.1797 2.81821 13.5292L10.249 4.10671L19.6715 11.5375C20.322 12.0504 21.2652 11.939 21.7781 11.2885C22.2911 10.638 22.1797 9.69479 21.5292 9.18183L10.9288 0.822207ZM89.4896 661.824L11.4896 1.82398L8.51037 2.17608L86.5104 662.176L89.4896 661.824Z"
              fill="#002F49"
            />
          </svg>
        </div>
        <div className="notFoundPageSecondColumn">
          <div className="nfpArrowTablet">
            <svg
              viewBox="0 0 315 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0607 0.939289C12.4749 0.353504 11.5251 0.353504 10.9393 0.939289L1.3934 10.4852C0.807611 11.071 0.807611 12.0208 1.3934 12.6066C1.97919 13.1923 2.92893 13.1923 3.51472 12.6066L12 4.12127L20.4853 12.6066C21.0711 13.1923 22.0208 13.1923 22.6066 12.6066C23.1924 12.0208 23.1924 11.071 22.6066 10.4852L13.0607 0.939289ZM13.5 60.0344L13.5 1.99995H10.5L10.5 60.0344H13.5Z"
                fill="white"
              />
              <path
                d="M304.061 1.93929C303.475 1.3535 302.525 1.3535 301.939 1.93929L292.393 11.4852C291.808 12.071 291.808 13.0208 292.393 13.6066C292.979 14.1923 293.929 14.1923 294.515 13.6066L303 5.12127L311.485 13.6066C312.071 14.1923 313.021 14.1923 313.607 13.6066C314.192 13.0208 314.192 12.071 313.607 11.4852L304.061 1.93929ZM304.5 61.0344V2.99995H301.5V61.0344H304.5Z"
                fill="#002F49"
              />
              <line
                x1="304"
                y1="59.5"
                x2="10"
                y2="59.5"
                stroke="#002F49"
                stroke-width="3"
              />
            </svg>
          </div>
          <div className="notFoundPageSubtitle">
            Ups, se pare că pagina pe care o cautați nu este disponibilă.
          </div>
          <div
            className="notFoundPageSubtitle nfpErrorTitle"
            // style={{ marginTop: 120 }}
          >
            <span className="notFoundPageErrorText mainColorText hideOn360">
              Eroare{" "}
            </span>
            <span className="notFoundPageErrorText whiteText">4</span>
            <span className="notFoundPageErrorText mainColorText">0</span>
            <span className="notFoundPageErrorText whiteText">4</span>
          </div>

          <div id="notFound-thrdContainer" className="notFoundPageSubtitle">
            <span>
              Te rugăm să folosești{" "}
              <span className="mainColorText displayInlineBlock">
                meniul de sus
              </span>{" "}
              pentru a selecta altă pagină.
            </span>
          </div>
        </div>
        <div className="notFoundPageThirdColumn nfpArrowDesktop">
          <svg
            id="notFound-rightArrow"
            width="155"
            height="546"
            viewBox="0 0 155 663"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M144.046 0.837629C144.706 0.318256 145.65 0.417621 146.156 1.05957L154.393 11.5212C154.899 12.1632 154.774 13.1047 154.114 13.6241C153.454 14.1435 152.51 14.0441 152.004 13.4022L144.682 4.10293L135.127 11.6267C134.467 12.146 133.523 12.0467 133.017 11.4047C132.512 10.7627 132.637 9.8212 133.296 9.30183L144.046 0.837629ZM55.8447 661.843L143.47 1.84311L146.453 2.15698L58.8282 662.157L55.8447 661.843Z"
              fill="#002F49"
            />
            <line
              y1="-1.5"
              x2="58.4599"
              y2="-1.5"
              transform="matrix(-1 0 0 1 58.46 662)"
              stroke="#002F49"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>
    </PageWithBackgroundImage>
  )
}
